var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"CardArea"},_vm._l((_vm.$root.$children[0].users),function(user,index){return _c('div',{key:index,staticClass:"card"},[_c('div',{staticClass:"card-header",staticStyle:{"padding-left":"10px","display":"inline-flex"}},[_c('i',{staticClass:"fad fa-user",staticStyle:{"color":"#5c7cfa"}}),_vm._v("   "),_c('p',[_vm._v(_vm._s(user.username))])]),_c('div',{staticClass:"card-body",staticStyle:{"padding":"10px"}},[_c('b',[_vm._v("Balance: ")]),_vm._v(_vm._s(_vm.$root.$children[0].api_capabilities.currency.symbol)+_vm._s(user.balance)),_c('br'),_c('b',[_vm._v("Owned servers: ")]),_vm._v(_vm._s(_vm.$root.$children[0].user.servers.filter( function (server) { return server.owner == user.pterodactyl_id; } ).length)+" "),_c('br'),_c('b',[_vm._v("Owned VPSs: ")]),_vm._v(_vm._s(_vm.$root.$children[0].user.vps.filter(function (vps) { return vps.parent == user.id; }) .length)),_c('br'),_c('div',{staticClass:"user-control-holder"},[(
              _vm.$root.$children[0].user.vps.filter(
                function (vps) { return vps.parent == user.id; }
              ).length > 0
            )?_c('button',{staticClass:"btn btn-primary",staticStyle:{"color":"var(--bs-button-text)","min-height":"40px","margin":"5px"},on:{"click":function($event){return _vm.gotoVpsOverviewUser(user.id)}}},[_c('i',{staticClass:"fad fa-eye",staticStyle:{"color":"var(--bs-button-text)","position":"relative","left":"-2px"}}),_vm._v(" View VPSs ")]):_vm._e(),(
              _vm.$root.$children[0].user.servers.filter(
                function (server) { return server.owner == user.pterodactyl_id; }
              ).length > 0
            )?_c('button',{staticClass:"btn btn-primary",staticStyle:{"color":"var(--bs-button-text)","min-height":"40px","margin":"5px"},on:{"click":function($event){return _vm.gotoServerOverviewUser(user.pterodactyl_id)}}},[_c('i',{staticClass:"fad fa-eye",staticStyle:{"color":"var(--bs-button-text)","position":"relative","left":"-2px"}}),_vm._v(" View Servers ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary",staticStyle:{"color":"var(--bs-button-text)","min-height":"40px","margin":"5px"},on:{"click":function($event){return _vm.gotoUserOverview(user.id)}}},[_c('i',{staticClass:"fad fa-eye",staticStyle:{"color":"var(--bs-button-text)","position":"relative","left":"-2px"}}),_vm._v(" View user ")])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }